<script>
import store from '@/infrastructure/store'
import MyItemsList from '../components/MyItemsList'
import AddItem from '../components/AddItem'
import * as itemFilters from '@/features/items/services/itemFilters'
import * as itemSorters from '@/features/items/services/itemSorters'
import ItemSortAndFilterCard from '@/features/items/components/ItemSortAndFilterCard'
import { computed } from '@vue/composition-api'
import {
  useItemFiltering,
  useItemSearching,
  useItemSorting,
  useQuerySyncForItemFiltering,
  useQuerySyncForItemSearching,
  useQuerySyncForItemSorting,
} from '@/features/items/composables/itemComposables'

export default {
  components: {
    MyItemsList,
    AddItem,
    ItemSortAndFilterCard,
  },

  setup() {
    const authUserId = computed(() => store.getters['auth/authUserId'])

    const allItems = computed(() => store.getters['items/myUnarchivedItems'])

    const filterOptions = [
      //
      itemFilters.hasPriceFilter,
      itemFilters.hasRatingFilter,
      itemFilters.hasShopFilter,
    ]

    const sortOptions = [
      itemSorters.createByNameSorter(),
      itemSorters.createByNameSorter({ descending: true }),
      itemSorters.createByPriceSorter(),
      itemSorters.createByPriceSorter({ descending: true }),
      itemSorters.createByRatingSorter({ descending: true }),
      itemSorters.createByRatingSorter(),
      itemSorters.createByCreatedDateSorter({ descending: true }),
      itemSorters.createByCreatedDateSorter(),
    ]

    const { searchText, itemsMatchingSearchQuery } = useItemSearching({ items: allItems })
    const { selectedFilterOptions, filteredItems } = useItemFiltering({
      items: itemsMatchingSearchQuery,
      authUserIdRef: authUserId,
    })
    const { selectedSortOption, sortedItems } = useItemSorting({ items: filteredItems })

    const defaultSortOption = sortOptions[0]
    selectedSortOption.value = defaultSortOption

    const { onUserUpdatedSearchText } = useQuerySyncForItemSearching({
      searchTextRef: searchText,
    })

    const { onUserUpdatedSelectedFilterOptions } = useQuerySyncForItemFiltering({
      filterOptions: filterOptions,
      selectedFilterOptionsRef: selectedFilterOptions,
    })

    const { onUserUpdatedSelectedSortOption } = useQuerySyncForItemSorting({
      sortOptions: sortOptions,
      selectedSortOptionRef: selectedSortOption,
      defaultSortOption: defaultSortOption,
    })

    return {
      filterOptions,
      sortOptions,

      onUserUpdatedSearchText,
      onUserUpdatedSelectedFilterOptions,
      onUserUpdatedSelectedSortOption,
      searchText,
      selectedFilterOptions,
      selectedSortOption,

      allItems: allItems,
      displayedItems: sortedItems,
    }
  },

  data() {
    return {
      isLoadingItems: false,
      animateAddItemElement: false,
      highlightedItemScrollOffset: 80,
      highlightedItemId: undefined,
    }
  },

  computed: {
    hasAnyItems() {
      return this.allItems.length > 0
    },
  },

  watch: {
    animateAddItemElement: {
      handler(to) {
        if (to) {
          setTimeout(() => {
            this.$nextTick(() => {
              this.$vuetify.goTo(this.$refs.addItemElement, {
                appOffset: true,
                offset: 0,
              })

              setTimeout(() => {
                this.$nextTick(() => {
                  setTimeout(() => {
                    this.animateAddItemElement = false
                  }, 2000)
                })
              }, 500)
            })
          }, 50)
        }
      },
      immediate: true,
    },
  },

  created() {
    this.loadItems()
  },

  mounted() {
    if (this.$route.query.highlightedItemId) {
      this.highlightedItemId = this.$route.query.highlightedItemId
    }
  },

  methods: {
    async loadItems() {
      this.isLoadingItems = true
      try {
        await store.dispatch('items/fetchMyItems')
      } finally {
        this.isLoadingItems = false
      }
    },
  },
}
</script>

<template>
  <div>
    <v-card :loading="isLoadingItems">
      <v-card-title>
        <div class="mx-auto text-center">My items</div>
      </v-card-title>
    </v-card>

    <template v-if="hasAnyItems">
      <div class="pt-3"></div>

      <ItemSortAndFilterCard
        :search-text.sync="searchText"
        :filter-options="filterOptions"
        :selected-filter-options.sync="selectedFilterOptions"
        :sort-options="sortOptions"
        :selected-sort-option.sync="selectedSortOption"
        @update:searchText="onUserUpdatedSearchText"
        @update:selectedFilterOptions="onUserUpdatedSelectedFilterOptions"
        @update:selectedSortOption="onUserUpdatedSelectedSortOption"
      >
        <template v-slot:extra-buttons>
          <v-btn icon @click="animateAddItemElement = true">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
      </ItemSortAndFilterCard>
    </template>

    <div class="pt-3"></div>

    <MyItemsList
      :items="displayedItems"
      :highlighted-item-id="highlightedItemId"
      :highlighted-item-scroll-offset="highlightedItemScrollOffset"
      item-css-class="mb-3"
    />

    <AddItem ref="addItemElement" :class="{ shake: animateAddItemElement }" />

    <div class="pt-3"></div>

    <v-card>
      <v-card-text>
        <v-btn block large text :to="{ name: 'my-archived-items' }">Show archived items</v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>
